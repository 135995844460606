import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/contact.scss';

const ContactPage = () => (
    <Layout>
        <SEO title="contact" />
        <div className="headerText">
            contact
        </div>
        <div className="contact-content">
            <div className="socials">
                <a href='https://www.instagram.com/SpringCityCoffee' target='_blank'>instagram</a> &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href='https://www.facebook.com/SpringCityCoffee' target='_blank'>facebook</a>  &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href='https://www.twitter.com/SpringCityCoffe' target='_blank'>twitter</a> 
            </div>
            <p>
                I'd love to hear from you!
            </p>
            <p>
                Put your info in the boxes below, along with a short message, and I'll 
                reach out soon.
            </p>
            <p>
                <form name="contact" method="POST" data-netlify="true" action="thank-you" id="contactForm">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="from-group" id="emailForm">
                        <input type="email" name="email" className="form-control" id="email" placeholder="Your Email" required/>
                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                        <br/>
                        <textarea name="message" className="form-control" id="message" placeholder="Your Message" required></textarea>
                        <button className="btn-sm btn-primary" id="btnSend" >
                            Send
                        </button>
                    </div>
                </form>
            </p>
        </div>
    </Layout>
);

export default ContactPage;